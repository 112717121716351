import React from "react"
import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../../assets/styles/layout"

import { FooterContainer } from "../../components/global/common/containers"

import CareersHeader from "../../components/careers/CareersHeader"
import Reasons from "../../components/careers/Reasons"
import Gallery from "../../components/careers/Gallery"
import JobOffers from "../../components/careers/JobOffers"

import CallToAction from "../../components/global/CallToAction"
import Footer from "../../components/global/Footer"

export const query = graphql`
  query ($id: String!, $language: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      acfCallToAction {
        ctaPersonPosition
        ctaPersonName
        ctaForm
        ctaImage {
          altText
          atttachement_pl {
            altTextPl
          }
          sourceUrl
          srcSet
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        ctaContactOptions {
          eMail
          phone
        }
        ctaButtons {
          ctaButton1 {
            url
            title
            target
          }
          ctaButton2 {
            target
            url
            title
          }
        }
        ctaIntro
        ctaTitle
      }
      acfCareers {
        careersGalleryExists
        careersGalleryTitle
        careersIntroBigDescription
        careersIntroSmallDescription
        careersReasonsExists
        intro
        jobOffersTitle
        jobOffersExists
        introLink {
          url
          title
          target
        }
        featuredImage {
          altText
          atttachement_pl {
            altTextPl
          }
          sourceUrl
          srcSet
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        careersReasonsTitle
        careersReasonsList {
          header
          description
        }
        careersGallery {
          id
          altText
          atttachement_pl {
            altTextPl
          }
          sourceUrl
          srcSet
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allWpJobOffer(filter: { language: { slug: { eq: $language } } }) {
      nodes {
        acfSingleJobOffer {
          jobOfferName
          jobOfferLocation
          jobOfferIcon
          jobOfferForm
        }
        id
        slug
      }
    }
  }
`

const Careers = ({ pageContext, data }) => {
  const { seo } = data.wpPage
  const wpCareers = data.wpPage.acfCareers

  const is = {
    reasons: wpCareers.careersReasonsExists,
    gallery: wpCareers.careersGalleryExists,
    jobOffers: wpCareers.jobOffersExists,
  }

  return (
    <Layout>
      {seo && process.env.GATSBY_SITE_ENV === "production" && (
        <Seo post={data.wpPage} />
      )}
      <CareersHeader data={wpCareers} pageContext={pageContext} />
      {is.reasons && <Reasons data={wpCareers} />}
      {is.gallery && <Gallery data={wpCareers} />}
      {is.jobOffers && <JobOffers data={data} />}
      <FooterContainer>
        <CallToAction data={data.wpPage.acfCallToAction} />
        <Footer />
      </FooterContainer>
    </Layout>
  )
}

export default Careers
